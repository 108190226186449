import React from 'react'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/login/AuthContext'
import { SignalRPrivateProvider } from 'src/context/signalrContext/SignalRPrivateContext'
import { SignalRPublicProvider } from 'src/context/signalrContext/SignalRPublicContext'

export const ProtectedRoute = (props) => {
  const { children } = props
  const { token } = useContext(AuthContext)
  if (!token?.accessToken) {
    // user is not authenticated
    return <Navigate to="/" />
  }
  return (
    <SignalRPublicProvider>
      <SignalRPrivateProvider>{children}</SignalRPrivateProvider>
    </SignalRPublicProvider>
  )
}
